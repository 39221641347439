export default {
  updated: function (el) {
    const blockedWords = ["porn", "sex", "xxx", "adult", "nsfw"];
    const blockedLinks = [
      "pornhub",
      "xvideos",
      "youporn",
      "redtube",
      "marketinglink1",
      "marketinglink2",
      "marketinglink3",
    ];

    if (el.value) {
      const input = el.value.toLowerCase();
      for (let i = 0; i < blockedWords.length; i++) {
        if (input.includes(blockedWords[i])) {
          el.value = "";
          el.style.borderColor = "red";
          return;
        } else {
          el.style.borderColor = "";
        }
      }

      for (let i = 0; i < blockedLinks.length; i++) {
        if (input.includes(blockedLinks[i])) {
          el.value = "";
          return;
        } else {
          el.style.borderColor = "";
        }
      }
    }
  },
};
