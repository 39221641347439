import english from "./translations/en.json";
import french from "./translations/fr.json";
import kinyarwanda from "./translations/rw.json";
import { languages } from "./languages.js";

export const setLanguage = (lang) => {
  localStorage.setItem("country", JSON.stringify(lang));
  localStorage.setItem("lang", lang.code);
  location.reload();
};

export const getCountry = () => {
  var country = localStorage.getItem("country");
  if (country) {
    country = JSON.parse(country);
  } else {
    country = languages[0];
    localStorage.setItem("country", JSON.stringify(country));
    localStorage.setItem("lang", this.country.code);
  }
  return country;
};

export const getLanguage = () => {
  let lang = localStorage.getItem("lang") || "en";
  switch (lang) {
    case "fr":
      return french;
    case "rw":
      return kinyarwanda;
    default:
      return english;
  }
};

export const trans = {
  install(app) {
    app.config.globalProperties.$trans = (text, variables = {}) => {
      const translations = getLanguage();
      //return translations[text.trim()] || text;
      if (typeof translations[text] !== "undefined") {
        // Replace variables in the translated text
        let translatedText = translations[text];
        for (let variable in variables) {
          translatedText = translatedText.replace(
            "{{" + variable + "}}",
            variables[variable]
          );
        }
        return translatedText;
      } else {
        // Return the original text if no translation exists
        return text;
      }
    };
  },
};
