export default {
  install: (app) => {
    app.config.globalProperties.$helper = {
      generateFormData(obj) {
        let fd = new FormData();
        for (let key in obj) {
          fd.append(
            key,
            typeof obj[key] == "object" ? JSON.stringify(obj[key]) : obj[key]
          );
        }
        return fd;
      },
      formatDate(date) {
        var d = new Date(date);
        const options = {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        return d.toLocaleDateString("en-US", options);
      },
      shortDate(userDate) {
        const currentDate = new Date(
          userDate.toLocaleString("fr-CA", {}).slice(0, 10)
        );

        const year = currentDate.getFullYear();
        const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        const formattedDate = `${month}-${year}`;
        return formattedDate;
      },
      formatNumber(num) {
        if (num) {
          if (num.toString().indexOf(".") > -1) num = Number(num).toFixed(3);
        }
        return Number(num)
          ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0;
      },
      empty(mixedVar) {
        let undef, key, i, len;
        const emptyValues = [undef, null, false, 0, "", "0"];
        for (i = 0, len = emptyValues.length; i < len; i++) {
          if (mixedVar === emptyValues[i]) {
            return true;
          }
        }
        if (typeof mixedVar === "object") {
          for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
              return false;
            }
          }
          return true;
        }
        return false;
      },
      padNumber(number, targetedLength = 5) {
        let strNumber = number.toString();
        if (strNumber.length < targetedLength) {
          let padding = new Array(targetedLength - strNumber.length + 1).join(
            "0"
          );
          return padding + strNumber;
        }
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      inputTitle(text) {
        return this.capitalizeWords(text.split("_").join(" "));
      },
      createTitleSlug(text) {
        return text.toLowerCase().split(" ").join("_");
      },
      createKeyPath(keys = []) {
        return keys.join(".");
      },
      getKeyValue(path, obj) {
        return path.split(".").reduce((p, c) => (p && p[c]) || null, obj);
      },
      timeDifference(target, current = new Date()) {
        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
        var elapsed = target - current;
        var nume, unit;
        if (elapsed > 0) {
          if (elapsed < msPerMinute) {
            nume = Math.round(elapsed / 1000);
            unit = "second";
          } else if (elapsed < msPerHour) {
            nume = Math.round(elapsed / msPerMinute);
            unit = "minute";
          } else if (elapsed < msPerDay) {
            nume = Math.round(elapsed / msPerHour);
            unit = "hour";
          } else if (elapsed < msPerMonth) {
            nume = Math.round(elapsed / msPerDay);
            unit = "day";
          } else if (elapsed < msPerYear) {
            nume = Math.round(elapsed / msPerMonth);
            unit = "month";
          } else {
            nume = Math.round(elapsed / msPerYear);
            unit = "year";
          }
        } else {
          if (elapsed > -1 * msPerMinute) {
            nume = Math.round(elapsed / 1000);
            unit = "second";
          } else if (elapsed > -1 * msPerHour) {
            nume = Math.round(elapsed / msPerMinute);
            unit = "minute";
          } else if (elapsed > -1 * msPerDay) {
            nume = Math.round(elapsed / msPerHour);
            unit = "hour";
          } else if (elapsed > -1 * msPerMonth) {
            nume = Math.round(elapsed / msPerDay);
            unit = "day";
          } else if (elapsed > -1 * msPerYear) {
            nume = Math.round(elapsed / msPerMonth);
            unit = "month";
          } else {
            nume = Math.round(elapsed / msPerYear);
            unit = "year";
          }
        }
        const rtf = new Intl.RelativeTimeFormat();
        return rtf.format(nume, unit);
      },
      capitalizeWords(sentence) {
        var words = sentence.toLowerCase().split(" ");
        for (var i = 0; i < words.length; i++) {
          var word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(" ");
      },
    };
  },
};
